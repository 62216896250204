<template>
  <div class="my">
    <p class="title">个人中心</p>
    <div class="user">
      <img
        :src="$store.state.userInfo.icon"
        alt=""
      />
      <span>{{$store.state.userInfo.nickName}}</span>
    </div>
    <div class="bodyer">
      <li @click="goAddress">
        <img src=".././assets/dizhibu.png" alt="">
        <span>收件人地址簿</span>
      </li>
    </div>
  </div>
</template>

<script>
import {get,post} from '@/utils/http'
export default {

  methods:{

    goAddress(){
      this.$router.push('/address')
    }
  }
};
</script>

<style lang="less" scoped>
.my {
  height: 100%;
  background-color: #489ef6;
  display: flex;
  flex-direction: column;

  .title {
    padding: 20px;
    font-size: 18px;
    color: white;
  }
  .bodyer {
    // padding-top: 20px;
    padding: 20px 20px 0;
    margin-top: 20px;
    flex: 1;
    background-color: white;
    li{
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100px;
      img{
        width: 80px;
        height: 80px;
      }
      span{
        margin-top: 10px;
      }
    }
  }
  .user {
    display: flex;
    align-items: center;
    padding:  0 20px;
    img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
    span{
      margin-left: 20px;
      font-size: 20px;
      color: white;
    }
  }
}
</style>